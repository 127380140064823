import { Property, type PreviewImage } from "interfaces";
import { getSignedUrlAPI, uploadPropertyImagesAPI } from "./properties";
import { BASE_AWS_S3_URL } from "./constants";

export const getPreviewUrls = async (
    previewImages: PreviewImage[],
): Promise<PreviewImage[] | void> => {
    if (!previewImages) return;
    for (const previewImg of previewImages) {
        if (!previewImg.file.name) return;
        // let formId = previewImg.file.name;
        // let form = document.createElement("form");
        // form.id = formId;

        const signedResponse = await getSignedUrlAPI(previewImg.file.name);
        if (!signedResponse) throw new Error("Could not get signed url");

        const awsBaseUrl = signedResponse.url;
        const signedPreviewUrl = awsBaseUrl + signedResponse.fields.key;
        // previewImg["previewUrl"] = signedPreviewUrl;
        previewImg.uploadFields = signedResponse.fields;
    }

    return previewImages;
};

export const uploadImagesToAWS = async (
    previewImages: PreviewImage[],
    property: Property,
) => {
    try {
        const promises: Promise<any>[] = [];
        previewImages.forEach((image, idx) => {
            const formData = new FormData();
            formData.append("image", image.file);
            formData.append("image_name", image.file.name);
            if (!property.id) {
                throw new Error("Property ID not found");
            }
            formData.append("property_id", property.id.toString());
            formData.append("order", idx.toString());
            const promise = uploadPropertyImagesAPI(formData);
            promises.push(promise);
        });
        const res = await Promise.all(promises);

        return res;
    } catch (error) {
        console.error(error);
        throw new Error("Error uploading images");
    }
};
export const uploadImagesToAWSOld = async (previewImages: PreviewImage[]) => {
    for (const image of previewImages) {
        const fields = image.uploadFields;
        const formData = new FormData();
        for (const key in fields) {
            const value = fields[key];
            formData.append(key, value);
        }
        formData.append("file", image.file);
        if (!BASE_AWS_S3_URL) {
            alert("Keine Verbindung zu S3 möglich");
            return;
        }
        // const uploadSignedImageResponse = await postToSignedUrlAPI(formData);
    }
};

export const defaultPropertyImageUrl =
    "https://property-default-images.s3.eu-central-1.amazonaws.com/house.jpg";
